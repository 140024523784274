<template>
  <div :class="`custom-tab  bg-ems-gray800 h-full px-[16px] overflow-hidden`">
    <div class="w-full mt-[10px] h-[92vh] pb-5">
      <InputSearch v-model="userName" :placeholder="t('common.search')" />
      <div
        ref="scrollContainer"
        class="hidden-scroll overflow-x-hidden"
        :style="{ height: `${windowHeight - 280}px` }"
        @scroll="handleScroll"
      >
        <div v-for="(entry, entryIndex) in filterChannel" :key="entryIndex">
          <ItemUser
            :peerId="entry.channelId || ''"
            :onCall="false"
            :avt="entry.avatarUrl || ''"
            :id-hover="hoveredItem"
            :id-select="idSelect"
            :peerName="entry.name"
            :dialogType="2"
            @handle-select-item-user="handleClick(entry)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { TAB_COORDINATION, PEER_TYPE } from '@/util/common-constant.js';
import {  useRoute } from 'vue-router';
import _ from 'lodash';
import { debounce } from 'lodash';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ItemUser from './item-user.vue';
import InputSearch from '../input-search.vue';
const { t } = useI18n();
const { state, dispatch } = useStore();
const listChannelsInit = computed(() => state.coordinator.listChannelsInit);
const totalElements = computed(() => state.coordinator.totalElementsChannel);
const route = useRoute();
const idSelect = ref('');
const scrollContainer = ref(null);
const DEBOUNCE_TIME = 500;
const page = ref(0);
const userName = ref('');
const hoveredItem = ref('');
const initScroll = ref(true);
onMounted(async () => {
  await fetchData(page.value, true, userName.value);
});

const handleScroll = () => {
  if (scrollContainer.value) {
    const scrollTop = scrollContainer.value.scrollTop;
    const scrollHeight = scrollContainer.value.scrollHeight;
    const offsetHeight = scrollContainer.value.offsetHeight;
    if (scrollTop + offsetHeight >= scrollHeight - 1) {
      if (page.value <= Math.floor(totalElements.value / 10) + 1) {
        page.value++;
        fetchData(page.value, false, userName.value);
      }
    }
  }
};
const fetchData = async (page, firstData, q) => {
  dispatch('coordinator/getListChannels', {
    payload: { page: page, size: 10, q: q, activeOnly: true },
    firstData: firstData,
  }).then(() => {
    const item = listChannelsInit.value.find(
      (item) => item.channelId === route.query.id
    );
    if (initScroll.value) {
      if (item) {
        handleClick(item);
      } else {
        handleClick(listChannelsInit.value[0]);
      }
      initScroll.value = false;
    }
  });
};
const filterChannel = computed(() => {
  return listChannelsInit.value.filter((user) =>
    user.name.toLowerCase().includes(userName.value.toLowerCase())
  );
});

const searchUser = debounce((query) => {
  fetchData(page.value, true, query);
}, DEBOUNCE_TIME);

watch(userName, (newVal) => {
  userName.value = newVal;
  searchUser(newVal);
});

const handleClick = async (item) => {
  let query = {
    id: item.channelId,
    tab: TAB_COORDINATION.CHANNEL,
  };
  dispatch('coordinator/handleSetParams', query);
  idSelect.value = item.channelId;
  dispatch('coordinator/getMessagesByDialogs', {
    dialogId: item.channelId,
    dialogType: PEER_TYPE.CHANNEL,
    isChated: true,
  });
  let participants = await dispatch(
    'coordinator/getParticipants',
    item.channelId
  );
  if (participants) {
    let ids = [];
    for (let key in participants) {
      ids.push(participants[key].userId);
    }
    await dispatch('coordinator/getListStatus', ids.join(','));
  }
  let value = {
    dialogType: PEER_TYPE.CHANNEL,
    otherInfo: {
      peerAvatarUrl: item.avatarUrl,
      peerId: item.channelId,
      peerName: item.name,
      peerType: PEER_TYPE.CHANNEL,
      status: item.active,
      like: item.liked,
    },
    participants:[],
    tab: TAB_COORDINATION.CHANNEL,
  };
  dispatch('coordinator/selectValue', value);
};
</script>
<style>

</style>
